import React from 'react'
import { useLocation } from 'react-router-dom'
import {
    MDBMask,
    MDBRow,
    MDBCol,
    MDBView,
    MDBContainer,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCard,
    MDBTypography 
  } from 'mdbreact'
import Carousel from './CarouselMultiItem'
import MDBAvatar from './Avatar'
import { CircleToBlockLoading } from 'react-loadingg';
import './styles/Slider.css'

const Slider = ({contentSlider, cultureList, Link, Load}) => {
    const location = useLocation()

    
    const Discover = () =>{
        return cultureList.map((item, index) => {
            return (
                <div key={index}>
                    <MDBAvatar className="mx-auto">
                        <Link to={item.link}>
                        <div style={{
                            backgroundImage: `url(${item.images[0]})`, 
                            backgroundSize: 'cover',
                            backgroundPosition:'center', 
                            height:"150px",
                            width: "150px",
                            margin:'auto'}}
                            className="rounded-circle z-depth-1">

                        </div>
                        </Link>
                    </MDBAvatar>
                    <h4 className="font-weight-bold  my-4">
                        <Link className="h4 dark-grey-text text-reset" to={item.link}>{item.name}</Link>
                    </h4>
                </div>
            )
        })
    }

    const loadDiscover = () =>{
        if (Load) {
            return <CircleToBlockLoading/>
        }
        return <Carousel>
                    {Discover()}
                </Carousel>
    }
    const ShowPanel = (location) =>{
        if (location.pathname === '/')
            return <MDBContainer size="md" style={{marginTop:'-7em'}}>
                        <MDBCard reverse>
                            <MDBCardBody cascade className="text-center">
                                <MDBCardTitle>
                                <MDBTypography tag="h5" className="h5-responsive font-weight-bold">
                                ¿Que te gustaría descubrir?
                                </MDBTypography>
                                </MDBCardTitle>
                                {loadDiscover()}
                                <hr/>
                                <MDBCardText>
                                <p className="lead  mx-auto ">
                                ¡Bienvenido(a) a nuestro sitio web! Nos complace compartir las riquezas culturales y tradiciones que nos identifican.
                                Explora los elementos esenciales que forman parte de nuestro set de servicios y sumérgete en la diversidad cultural que tenemos para ofrecerte. 
                            </p>
                                </MDBCardText>
                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBContainer>  
    }

    const IsLink = (data) =>{
       
        if (data.route === ''){
            return <MDBTypography style={{fontSize:'42px'}} tag="h1"  className='h1-responsive white-text font-weight-bold '>
                {data.primaryContent}
                <span className='green-text font-weight-bold'> {data.secondaryContent} </span>
            </MDBTypography>
        }else{
            return <Link to={data.linkTo}>
                <MDBTypography style={{fontSize:'42px'}} tag="h1"  className='h1-responsive white-text font-weight-bold '>
                    {data.primaryContent}
                    <span className='green-text font-weight-bold'> {data.secondaryContent} </span>
                </MDBTypography>
            </Link>
        }
    }

    const truePicture = (data, index) => {
        return <MDBView key={index}
                    src={data.images[0]}
                    fixed
                    className="mb-2"
                    >
                    <MDBMask className='rgba-black-medium' />
                    <MDBContainer
                        className='d-flex justify-content-center align-items-center'
                        style={{ height: '100%', width: '100%', paddingTop: '15rem' }}
                    >
                        <MDBRow>
                            <MDBCol md='12' className='mb-4 white-text text-center'>
                                {IsLink(data)}
                                <hr className='hr-light my-4' />
                                <h5 className='text-uppercase pt-md-5 pt-sm-2 pt-5 pb-md-5 pb-sm-3 pb-5 white-text font-weight-bold'>
                                
                                </h5>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBView>
    }

    const setContentSlider = (contentSlider, location) => {
        if (Load) {
            return <MDBView  fixed
                            className="mb-2"
                            src='https://firebasestorage.googleapis.com/v0/b/rincondelahuasteca-4a673.appspot.com/o/1617335852683-backPatrocinador.jpg?alt=media&token=d71f55f1-aabc-4f84-80ff-da057b995611'>
                                <MDBMask className='rgba-black-medium' />
                    <MDBContainer
                        className='d-flex justify-content-center align-items-center'
                        style={{ height: '100%', width: '100%', paddingTop: '15rem' }}
                    >
                        <MDBRow>
                            <MDBCol md='12' className='mb-4 white-text text-center'>
                                <CircleToBlockLoading />
                                <hr className='hr-light my-4' />
                                <h5 className='text-uppercase pt-md-5 pt-sm-2 pt-5 pb-md-5 pb-sm-3 pb-5 white-text font-weight-bold'>
                                
                                </h5>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                     
                    </MDBView>
        }
        return contentSlider.map((data, index) => {
            if (data.route === '/' && location.pathname === '/') {
                return truePicture(data, index)
            }else{
                let datarout = data.route.substring(1)
                let reg = new RegExp(datarout+".*")
                let regex = reg.exec(location.pathname)
                if (regex !== null  && data.route !== '/') {
                    return truePicture(data, index)
                }
            }
        })
    }

    return <>
                {setContentSlider(contentSlider, location)}
                {ShowPanel(location)}
            </>
    
}

export default Slider