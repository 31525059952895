import React, {useState}from "react";
import {
  MDBContainer, 
  MDBRow, 
  MDBCol, 
  MDBCard, 
  MDBCardBody, 
  MDBIcon, 
  MDBBtn, 
  MDBInput,
  MDBCardText,
  MDBAlert 
} from "mdbreact";
import  emailjs, { init } from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

init("user_0yoAbNkuZrxPs04jx7QDH");

const Contacto = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [loading, setLoading] = useState(false)
  const [finishMessage, setFinishMessage] = useState(['', '', 'none'])


  const submitHandler = (event) =>{
    event.preventDefault();
    event.target.className += " was-validated";
    if (name == ''  ||
        email == '' ||
        subject == '' ||
        message == '' ||
        captcha == '') {
      return false  
    }
    setLoading(true)

    emailjs.sendForm('default_service', 'template_ndv7wrp', event.target, 'user_0yoAbNkuZrxPs04jx7QDH')
      .then((result) => {
        setFinishMessage(['Mensaje enviado!', 'success', 'block'])
        setLoading(false)
        setName('')
        setEmail('')
        setSubject('')
        setMessage('')
        setCaptcha('')
      }, (error) => {
        setFinishMessage([error.text, 'danger', 'block'])
        setLoading(false)
      });

    
  }

  const handlerName = event => {
    setName(event.target.value)
  }
  const handlerEmail = event => {
    setEmail(event.target.value)
  }
  const handlerSubject = event => {
    setSubject(event.target.value)
  }
  const handlerMessage = event => {
    setMessage(event.target.value)
  }

  const handleCaptcha = (value) => {
    //console.log(value)
    setCaptcha(value)
  }

  return (
    <main>
        <MDBContainer >
        <MDBCard className="px-3 mb-2" style={{marginTop:'-5em'}} >
              <MDBCardBody>
                  <MDBCardText >
                    <p className="lead text-center w-responsive mx-auto ">
                    Estamos para servirte, escribenos tu inquietud y responderemos con gusto.<br/>
                    Por favor, rellena los datos solicitados.<br/> 
                    </p>
                  <span className="float-right font-italic">Tu información es confidencial. Más informació en nuestro <Link to="/privacidad">Aviso de Privacidad</Link>.</span>
                  </MDBCardText>
              </MDBCardBody>
          </MDBCard>
          <MDBRow>
            <MDBCol lg="5" className="lg-0 mb-4">
            <form
                className="needs-validation"
                onSubmit={submitHandler}
                noValidate
              >
              <MDBCard>
                <MDBCardBody>
                  <div className="md-form">
                    <MDBInput
                      autocomplete="off"
                      icon="user"
                      label="Nombre"
                      iconClass="grey-text"
                      type="text"
                      id="form-name"
                      name="name"
                      value={name}
                      onChange={handlerName}
                      required
                    />
                  </div>
                  <div className="md-form">
                    <MDBInput
                     autocomplete="off"
                      icon="envelope"
                      label="Correo"
                      iconClass="grey-text"
                      type="email"
                      id="form-email"
                      name="email"
                      value={email}
                      onChange={handlerEmail}
                      required
                    />
                  </div>
                  <div className="md-form">
                    <MDBInput
                      icon="tag"
                      label="Asunto"
                      iconClass="grey-text"
                      type="text"
                      id="form-subject"
                      name="subject"
                      value={subject}
                      onChange={handlerSubject}
                      required
                    />
                  </div>
                  <div className="md-form">
                    <MDBInput
                      icon="pencil-alt"
                      label="Mensaje"
                      iconClass="grey-text"
                      type="textarea"
                      id="form-text"
                      name="message"
                      value={message}
                      onChange={handlerMessage}
                      required
                    />
                  </div>
                  <ReCAPTCHA
                    name='captcha'
                    sitekey="6LfkIpsaAAAAAIdSMikypDkv56UpjdKpKC4MZaPT"
                    onChange={handleCaptcha}
                    required
                  />
                  <MDBAlert color={finishMessage[1]} style={{display:finishMessage[2]}}>
                  {finishMessage[0]}
                  </MDBAlert>
                  <div className="text-center">
                    <MDBBtn disabled={loading === true} type="submit" color="dark-green" >{(loading) ? 'Enviando' : 'Enviar'}</MDBBtn>
                  </div>
                </MDBCardBody>
              </MDBCard>
              </form>
              
            </MDBCol>
            <MDBCol lg="7">
              <div
                id="map-container"
                className="rounded z-depth-1-half map-container"
                style={{ height: "400px" }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d759.7030764926512!2d-98.86356172717528!3d21.205306259853067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d6bfdec3b78b2b%3A0xde979c645d3706a8!2sRinc%C3%B3n%20de%20la%20Huasteca!5e0!3m2!1ses!2smx!4v1615738741036!5m2!1ses!2smx"
                  title="Rincon de la Huasteca"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                />
              </div>
              <br />
              <MDBRow className="text-center">
                <MDBCol md="4">
                  <MDBBtn tag="a" floating="true" color="green" className="accent-1">
                    <MDBIcon icon="map-marker-alt" />
                  </MDBBtn>
                  <p>Plazeta 7, Santiago Tamazunchale</p>
                  <p className="mb-md-0">San Luis Potosí. México.</p>
                </MDBCol>
                <MDBCol md="4">
                  <MDBBtn tag="a" floating="true" color="green" className="accent-1">
                    <MDBIcon icon="phone" />
                  </MDBBtn>
                  <p>+52 483 109 2074</p>
                  <p className="mb-md-0">Lun - Vie, 9:00-21:00</p>
                </MDBCol>
                <MDBCol md="4">
                  <MDBBtn tag="a" floating="true" color="green" className="accent-1">
                    <MDBIcon icon="envelope" />
                  </MDBBtn>
                  <p>rincondelahuasteca17@gmail.com</p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
    </main>
  )
}

export default Contacto;