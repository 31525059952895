import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter,MDBBtn,MDBIcon } from "mdbreact";
import './styles/Footer.css'

const Footer = () => {
  return (
    <MDBFooter color="light-green darken-4" className="font-small pt-4">
      
      <audio id="stream" controls preload="none"  className="radio">
        <source src="https://radio17.servidorderadio.net/8216/stream" type="audio/mpeg"/>
      </audio>
      <MDBContainer size="lg"  className="text-center text-md-left">
        <MDBRow>
            <MDBCol md="6">
                <h5 className="title">Acerca de Rincón de la Huasteca</h5>
                <p>
                Dedicados a compartir contenido multimedia: música, video y fotografía; ofreciendo servicios relacionados a estos.
                </p>
            </MDBCol>
            
            <MDBCol md="6" className="mb-3">
                <h5 className="title">Síguenos en nuestras redes sociales.</h5>
                <MDBBtn size="md" tag="a" color="blue" social="fb" target="_blank" href="https://www.facebook.com/rincondelahuasteca">
                    <MDBIcon fab icon="facebook-f" />
                </MDBBtn>
                <MDBBtn size="md" tag="a" color="red" social="yt" target="_blank" href="https://www.youtube.com/channel/UCEGdf4lBEK9IYznS8DrIhTQ">
                    <MDBIcon fab icon="youtube" />
                </MDBBtn>
                <MDBBtn size="md" tag="a" gradient="purple" target="_blank" social="in" href="https://www.instagram.com/rincondelahuasteca/">
                    <MDBIcon fab icon="instagram"  /> 
                </MDBBtn>
            </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          <p> <a href="/privacidad">Aviso de Privacidad</a></p>
          &copy; {new Date().getFullYear()} Copyright:  Rincón de la Huasteca
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default Footer;