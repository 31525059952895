import React from 'react'
import {Link} from 'react-router-dom'
import {
    MDBView,
    MDBMask,
    MDBContainer,
    MDBTypography,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBBtn
  } from 'mdbreact'
  import './styles/Slider.css'
  
const ParallaxSlide = ({name, image, linkTo}) =>{
    const isLink = (link) =>{
        if (link) {
            
            return <Link to={link} >
                        <MDBBtn color="dark-green" size="sm" >
                            Ver todos
                            <MDBIcon icon='angle-double-right' className='ml-2' />
                        </MDBBtn>
                    </Link>
        }
    }
    return <MDBView 
            src={image}
            fixed
            className="my-5"
            >
            <MDBMask className='rgba-black-medium' />
            <MDBContainer
                className='d-flex justify-content-center align-items-center'
                style={{ height: '100%', width: '100%', paddingTop: '5rem', paddingBottom: '5rem' }}
            >
                <MDBRow>
                    <MDBCol md='12' className=' white-text text-center'>
                        <MDBTypography  tag="h1"  className='h1-responsive white-text  font-weight-bold'>
                           {name}
                            <hr className='hr-light my-4' />
                            {isLink(linkTo)}
                        </MDBTypography>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBView>
}

export default ParallaxSlide