import React, {useState} from 'react';
import { Link}  from 'react-router-dom'
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse
    } from "mdbreact";
import Logo from '../img/logo.svg'
import './styles/Nav.css'


const Nav = () => {
    const [open, setOpen] = useState(false)
    
    const handleOpen = e => {
        let status = !open
        setOpen(status)
    }
    return (
        <MDBNavbar  color="bg-dark-nav" fixed="top" dark expand="md" scrolling transparent>
                <MDBNavbarBrand>
                    <Link to="/"><img style={{display:'block'}}  width="110px" src={Logo} alt="Rincon de la huasteca"/></Link>
                    <Link to="/"><h1 style={{display:'none'}} className="h3-responsive white-text font-weight-bold">RH</h1></Link>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={handleOpen}/>
                <MDBCollapse id="navbarCollapse3" isOpen={open} navbar>
                    <MDBNavbarNav   right>
                        <MDBNavItem onClick={handleOpen}>
                            <MDBNavLink exact    to="/">Inicio</MDBNavLink>
                        </MDBNavItem>
                        {/* <MDBNavItem onClick={handleOpen}>
                            <MDBNavLink exact   to="/nosotros">Nosotros</MDBNavLink>
                        </MDBNavItem> */}
                        <MDBNavItem onClick={handleOpen}>
                            <MDBNavLink exact   to="/servicios">Servicios</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem onClick={handleOpen}>
                            <MDBNavLink exact to="/descubre">Descubre</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem onClick={handleOpen}>
                            <MDBNavLink exact to="/contacto">Contacto</MDBNavLink>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
        </MDBNavbar>
    )
}
  




export default Nav