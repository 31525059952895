import React, {useState, useEffect} from 'react'
import {useRouteMatch} from 'react-router-dom'
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
  } from 'mdbreact'
import { CircleToBlockLoading } from 'react-loadingg';
import {API_URL} from '../../../config'
import Profile from '../../Profile'
import Items from '../../Items'
import Intereses from  '../../Intereses'
import SEO from '../../SEO'

const ChildAuthors = () => {
    const [authors, setAuthors] = useState([])
    const [categorie, setCategorie] = useState([])
    const [intereses, setIntereses] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingItems, setLoadingItems] = useState(true)
    const [loadingInt, setLoadingInt] = useState(true)
    let { url } = useRouteMatch()
    
    useEffect(() => {
        setLoading(true)
        setLoadingItems(true)
        setLoadingInt(true)
        const headers = {
        'Content-Type':'application/json'
        }
        console.log(API_URL)
        fetch(API_URL + 'categorie', {
            headers: headers,
            method:'POST',
            body: JSON.stringify({'id':url.substr(1)})
        })
        .then(res => res.json())
        .then(data => {
            setCategorie(data.res[0])
            setLoading(false)

            fetch(API_URL + 'authors', {
                headers: headers,
                method:'POST',
                body: JSON.stringify({'id':url.substr(1)})
            })
            .then(res => res.json())
            .then(items => {
                setAuthors(items.res)
                setLoadingItems(false)
                
                fetch(API_URL + 'getcategories')
                .then(res => res.json())
                .then(inter => {
                    setIntereses(inter.res)
                    setLoadingInt(false)
                })
            })
        })
        
            
    }, [url])

    const existItems = (info) => {
        if (info.length === 0) return null 
        return <>
                <hr/>
                <MDBCard>
                    <MDBCardBody>
                        <MDBRow>
                            <Items list={info}/>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
                </>
    } 


    const loadProfile = (data, status) =>{
        if (status) {
            return <CircleToBlockLoading/>
        }
        return  <Profile profileInfo={data}/>
    }

    const loadItems = (auth, status) =>{
        if (status) {
            return <CircleToBlockLoading/>
        }
        return  existItems(auth)
    }

    const loadInt = (data, exclude, status) => {
        if (status) {
            return <CircleToBlockLoading/>
        }
        return  <Intereses interes={data} exclude={exclude}/> 
    }

    
    return (
        <main>
            <MDBContainer size="lg">
                
                {loadProfile(categorie, loading)}
                {loadItems(authors, loadingItems)}
                <hr/>
                {loadInt(intereses, categorie, loadingInt)}

            </MDBContainer>
            <SEO
                title={categorie.name}
                description={categorie.shortDescription || categorie.description} 
                url={window.location.href}
                image={categorie.images}
                type="article"
             />
        </main>
              
    )
}

export default ChildAuthors