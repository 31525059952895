import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { CircleToBlockLoading } from 'react-loadingg';
import {API_URL} from '../../../config'
import Items from '../../Items'
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBRow,
    MDBCardFooter
  } from 'mdbreact'

const Descubre = () => {
    const [dataCategory, setDataCategory] = useState([])
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        fetch(API_URL + 'getcategories')
            .then(res => res.json())
            .then(data => {
                setDataCategory(data.res)
                setLoading(false)
            })
    }, [])
    
    if (loading) {
        return <CircleToBlockLoading/>
    }
    return (
        <main>
            <MDBContainer size="lg">
                <MDBCard  style={{marginTop:'-3em'}}>
                    <MDBCardBody>
                     <h3 className="text-center font-weight-bold green-text">Explora las riquezas culturales</h3>
                    </MDBCardBody>
                </MDBCard>
                <hr/>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardText> 
                            <Link to="/contacto">Contáctanos </Link>  
                            para mas información; tenemos lo mejor para ti.
                        </MDBCardText>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <MDBRow>
                            <Items list={dataCategory}/>
                         </MDBRow>
                    </MDBCardFooter>
                </MDBCard>
            </MDBContainer>
        </main>
    )
}

export default Descubre