import React, {useState, useEffect} from 'react'
import {useRouteMatch} from 'react-router-dom'
import { MDBContainer} from 'mdbreact';
import { CircleToBlockLoading } from 'react-loadingg';
import {API_URL} from '../../config'
import Profile from  '../Profile'

const Patrocinador = () => {
    const [partner, setPartner] = useState([])
    const [loading, setLoading] = useState(true)
    
    let { path, url } = useRouteMatch()
    
    useEffect(() => {
        const headers = {
        'Content-Type':'application/json'
        }
        fetch(API_URL + 'partner', {
            headers: headers,
            method:'POST',
            body: JSON.stringify({'id':url})
        })
        .then(res => res.json())
        .then(data => {
            setPartner(data.res[0])
            setLoading(false)
        })
            
    }, [])

    

    if (loading) {
        return <CircleToBlockLoading/>
    }
    return (
        <main>
            <MDBContainer size="lg">
                <Profile profileInfo={partner}/>
            </MDBContainer>
        </main>
    )
}


export default Patrocinador