import React from 'react'
import {Link, useRouteMatch} from 'react-router-dom'
import {
    MDBCol,
    MDBIcon,
    MDBBtn,
    MDBCard,
    MDBCardImage,
    MDBView,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBRow
  } from 'mdbreact'



const Services = ({services}) =>{
    let {url } = useRouteMatch()
    const fixUrl = (link) =>{
        let cleanSame = link
        if (url !== '/') {

            cleanSame = link.substr(link.lastIndexOf('/'), link.length)
        }
        let finalUrl =  url + cleanSame
        finalUrl = finalUrl.replace(/\/\//, '/')
        return finalUrl
    }
    return services.map((section,i) => {
        return <MDBCol md="4" key={i} className="my-2">
                <MDBCard narrow>
                    <MDBView cascade>
                        <Link to={fixUrl(section.link)}>
                            <div style={{
                                backgroundImage: `url(${(section.images.length) ? section.images[0] :  'https://firebasestorage.googleapis.com/v0/b/rincondelahuasteca-4a673.appspot.com/o/noimage.jpg?alt=media&token=ad13c7f0-cd69-4ba4-9058-86acec11d946'})`, 
                                backgroundSize: 'cover',
                                backgroundPosition:'center', 
                                height:"200px"}}>

                            </div>
                        </Link>
                    </MDBView>
                    <MDBCardBody >
                        <MDBRow className="m-auto">
                            <MDBCardTitle md="11" className="m-auto">{section.name}</MDBCardTitle>
                            <MDBIcon md="1" icon={section.icon} size="2x" className="m-auto" />
                        </MDBRow>
                        <hr />
                        <MDBCardText style={{minHeight:'8vh'}}>
                        {section.shortDescription}
                        </MDBCardText>
                        <Link to={fixUrl(section.link)}>
                            <MDBBtn color="dark-green" size="sm" >
                                Conocer más
                                <MDBIcon icon='angle-double-right' className='ml-2' />
                            </MDBBtn>
                        </Link>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>     
        
    })
}

export default Services