import React from 'react'
import {
  MDBIcon
} from 'mdbreact'

import Carousel from '../../CarouselMultiItem'
import MDBAvatar from '../../Avatar'
import { Link } from 'react-router-dom'

const Partners = ({data}) => {

    return <Carousel >
        {
        data.map((item, index) =>{
          return <div  key={index}>
            <MDBAvatar className="mx-auto">
              <Link to={item.link}>
                <div className="rounded img-thumbnail" style={{
                      backgroundImage: `url(${item.images[0]})`, 
                      backgroundSize: 'cover',
                      backgroundPosition:'center', 
                      height:"150px",
                      width: "150px"}}>

                  </div>
              </Link>
            </MDBAvatar>
            <h4 className="font-weight-bold mt-4">{item.name}</h4>
            <h6 className="blue-text font-weight-bold my-3">
              {item.type}
            </h6>
            <p className="font-weight-normal">
              <MDBIcon icon="quote-left" className="pr-2" />
              {item.description}
            </p>
            <div className="grey-text">
              <MDBIcon icon="map-marker-alt" />&nbsp;{item.shortDescription}
            </div>
          </div>
        })
        }
    </Carousel>
}

export default Partners