import React, {useState, useEffect} from 'react'
import {useRouteMatch} from 'react-router-dom'
import { MDBContainer} from 'mdbreact';
import { CircleToBlockLoading } from 'react-loadingg';
import {API_URL} from '../../../config'
import Profile from  '../../Profile'
import Intereses from  '../../Intereses'
import SEO from '../../SEO'

const ChildServicio = () => {
    const [service, setService] = useState([])
    const [intereses, setIntereses] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingInt, setLoadingInt] = useState(true)
    
    let {url } = useRouteMatch()
    
    useEffect(() => {
        setLoading(true)
        setLoadingInt(true)
        const headers = {
        'Content-Type':'application/json'
        }
        fetch(API_URL + 'servicio', {
            headers: headers,
            method:'POST',
            body: JSON.stringify({'id':url.substr(1)})
        })
        .then(res => res.json())
        .then(data => {
            setService(data.res[0])
            setLoading(false)
            fetch(API_URL + 'allservicios')
            .then(res => res.json())
            .then(data => {
                setIntereses(data.res)
                setLoadingInt(false)
            })
        })

        
            
    }, [url])

    
    const loadProfile = (data, status) =>{
        if (status) {
            return <CircleToBlockLoading/>
        }
        return <Profile profileInfo={data}/>
    }

    const loadInt = (data, exclude, status) =>{
        if (status) {
            return <CircleToBlockLoading/>
        }
        return  <Intereses interes={data} exclude={exclude}/> 
    }
    return (
        <main>
            <MDBContainer size="lg">
                {loadProfile(service, loading)}
                <hr/>
                {loadInt(intereses, service, loadingInt)}
            </MDBContainer>
            <SEO
                title={service.name}
                description={service.shortDescription || service.description} 
                url={window.location.href}
                image={service.images}
                type="article"
             />
        </main>
    )
}


export default ChildServicio