import React, {useState, useEffect} from 'react'
import {
    BrowserRouter as Router, 
    Route, 
    Switch, 
    Link
} from 'react-router-dom'
import Nav from './Nav'
import Slider from './Slider'
import Footer from './Footer'
import Home  from './pages/Home/Home'
import Patrocinador  from './pages/Patrocinador'
import Privacidad  from './pages/Privacidad'
import Servicios  from './pages/Servicios/Servicios'
import ChildServicio  from './pages/Servicios/ChildServicio'
import Descubre  from './pages/Descubre/Descubre'
import FilterWorks  from './pages/Descubre/FilterWorks'
import ChildAuthors  from './pages/Descubre/ChildAuthors'
import ChildWorks  from './pages/Descubre/ChildWorks'
import ChildWork  from './pages/Descubre/ChildWork'
import Contacto  from './pages/Contacto'
import NotFound  from './pages/NotFound'
import {API_URL} from '../config'
import './styles/App.css'


const App = () =>{
    const [sliderContent, setContentSlider] = useState([])
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetch(API_URL + 'slidercontent').
                then(res => res.json()).
                then(data => {
                    setContentSlider(data.res)
                    //setLoading(false)
                    fetch(API_URL + 'getcategories').
                            then(res => res.json()).
                            then(data => {
                                setCategories(data.res)
                                setLoading(false)
                            })
                })
        
    }, [])


    return (
        <div id='parallax'>
            <Router >
                <Nav />
                <Slider 
                    contentSlider={sliderContent} 
                    cultureList={categories}
                    Link={Link}
                    Load={loading}
                />
                <div>

                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/servicios" component={Servicios}/>
                        <Route exact path="/servicios/:nombre" component={ChildServicio} />
                        <Route exact path="/descubre" component={Descubre} />
                        <Route exact path="/contacto" component={Contacto} />
                        <Route exact path="/descubre/trabajos" component={FilterWorks} />
                        <Route exact path="/descubre/:categoria" component={ChildAuthors} />
                        <Route exact path="/descubre/:categoria/:authors" component={ChildWorks} />
                        <Route exact path="/descubre/:categoria/:authors/:works" component={ChildWork} />
                        <Route exact path="/patrocinadores/:nombre" component={Patrocinador} />
                        <Route exact path="/privacidad" component={Privacidad} />
                        <Route  component={NotFound} />

                    </Switch>
                </div>
            </Router>
            <Footer/>
        </div>
    )
}


export default App