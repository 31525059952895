import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { CircleToBlockLoading } from 'react-loadingg';
import Services from '../Home/Services'
import {API_URL} from '../../../config'
import {
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardText
  } from 'mdbreact'

const Servicios = () => {
    const [dataServices, setDataServices] = useState([])
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        fetch(API_URL + 'allservicios')
            .then(res => res.json())
            .then(data => {
                setDataServices(data.res)
                setLoading(false)
            })
    }, [])
    if (loading) {
        return <CircleToBlockLoading/>
    }
    return (
        <main>
            <MDBContainer size="lg">
                <MDBCard  style={{marginTop:'-3em'}}>
                    <MDBCardBody>
                    <h3 className="text-center font-weight-bold green-text">Conoce nuestros servicios</h3>
                    </MDBCardBody>
                    <MDBCardFooter></MDBCardFooter>
                </MDBCard>
                <hr/>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardText> 
                            <Link to="/contacto">Contáctanos </Link>  
                            para mas información; tenemos lo mejor para ti.
                        </MDBCardText>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <MDBRow>
                            <Services services={dataServices} />
                        </MDBRow>
                    </MDBCardFooter>
                </MDBCard>
            </MDBContainer>
        </main>
    )
}

export default Servicios