import React, {useState, useEffect} from 'react'
import {useRouteMatch} from 'react-router-dom'
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
  } from 'mdbreact'
import { CircleToBlockLoading } from 'react-loadingg';
import {API_URL} from '../../../config'
import Profile from '../../Profile'
import Items from '../../Items'
import Intereses from  '../../Intereses'
import SEO from '../../SEO'

const ChildWorks = () => {
    const [works, setWorks] = useState([])
    const [author, setAuthor] = useState([])
    const [intereses, setIntereses] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingWorks, setLoadingWorks] = useState(true)
    const [loadingInt, setLoadingInt] = useState(true)
    let { url } = useRouteMatch()
    
    useEffect(() => {
        setLoading(true)
        setLoadingWorks(true)
        setLoadingInt(true)
        const headers = {
        'Content-Type':'application/json'
        }
        fetch(API_URL + 'author', {
            headers: headers,
            method:'POST',
            body: JSON.stringify({'id':url.substr(1)})
        })
        .then(res => res.json())
        .then(data => {
            setAuthor(data.res[0])
            setLoading(false)

            fetch(API_URL + 'works', {
                headers: headers,
                method:'POST',
                body: JSON.stringify({'id':url.substr(1)})
            })
            .then(res => res.json())
            .then(works => {
                setWorks(works.res)
                setLoadingWorks(false)
                fetch(API_URL + 'authors', {
                    headers: headers,
                    method:'POST',
                    body: JSON.stringify({'id':url.substr(1, url.lastIndexOf('/')-1)})
                })
                .then(res => res.json())
                .then(inter => {
                    setIntereses(inter.res)
                    setLoadingInt(false)
        
                })
            })
        })
        
            
    }, [url])

    const existItems = (info) => {
        if (info.length === 0) return null 
        return <>
                <hr/>
                <MDBCard>
                    <MDBCardBody>
                        <MDBRow>
                            <Items list={info}/>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
                </>
    } 

    const loadAuthor = (a, l) => {
        if (l) {
            return <CircleToBlockLoading/>
        }
        return <Profile profileInfo={a}/>
    }

    const loadItems = (i, s) => {
        if (s) {
            return <CircleToBlockLoading/>
        }
        return existItems(i)
    }

    const loadInt = (data, exclude, status) => {
        if (status) {
            return <CircleToBlockLoading/>
        }
        return <Intereses interes={data} exclude={exclude}/> 
    }
    return <main>
            <MDBContainer size="lg">
                {loadAuthor(author, loading)}
                {loadItems(works, loadingWorks)}
                <hr/>
                {loadInt(intereses, author, loadingInt)}
            </MDBContainer>
            <SEO
                title={author.name}
                description={author.shortDescription || author.description} 
                url={window.location.href}
                image={author.images}
                type="article"
             />
        </main>
    
}

export default ChildWorks