import React from "react";
import Helmet from "react-helmet";

const SEO = ({title, description, url, image, type}) => {
    
    const IMG = "https://firebasestorage.googleapis.com/v0/b/rincondelahuasteca-4a673.appspot.com/o/rh_fb.png?alt=media&token=b76312b7-e0d0-4c85-a1ac-1bc18a2b4c30"
    const APP_ID = "1457962191209287"
    const Images = (img) => {
        if (img === undefined) return IMG 
        if (img.length === 0) return IMG
        return img[0]
        
    }
    let titleFix =  (title == 'Rincón de la Huasteca') ? 'Rincón de la Huasteca | Inicio' : 'Rincón de la Huasteca | '+ title

    return(
        <Helmet title = {titleFix}
                htmlAttributes={{ lang: "en" }}
                meta={[
                    {
                        name: `description`,
                        content: description,
                    },
                    {
                        property: "og:url",
                        content: url
                      },
                      {
                        property: "og:type",
                        content: type
                      },
                      {
                        property: "og:title",
                        content: titleFix
                      },
                      {
                        property: "og:description",
                        content: description
                      },
                      {
                        property: "og:image",
                        content: Images(image)
                      },
                      {
                        property: "fb:app_id",
                        content: APP_ID
                      }
                ]} />
          )
};


export default SEO;