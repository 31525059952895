import React, { useEffect, useState } from 'react'
import { CircleToBlockLoading } from 'react-loadingg';
import {
    MDBContainer,
    MDBRow,
  } from 'mdbreact'
import {API_URL, IMGDEFAULT} from '../../../config'
import Services from './Services'
import Ourwork from './Ourwork'
import Partners from './Partners'
import SEO from '../../SEO'
import ParallaxSlide from '../../ParallaxSlide';
import '../../styles/Home.css'

const Home = () =>{
    const [dataServices, setDataServices] = useState([])
    const [dataOurwork, setDataOurwork] = useState([])
    const [dataPartners, setDataPartners] = useState([])
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        fetch(API_URL + 'servicios').
            then(res => res.json()).
            then(data => {
                setDataServices(data.res)
                setLoading(false)

                fetch(API_URL + 'lastworks').
                then(res => res.json()).
                then(data => {
                    setDataOurwork(data.res)
                    setLoading(false)
                    
                    fetch(API_URL + 'partners').
                    then(res => res.json()).
                    then(data => {
                        setDataPartners(data.res)
                        setLoading(false)
                    })
                })
                
            })

        

        
            
    }, [])

    const PartnersExist = (info) => {
        if (info.length === 0) return null
        return (
            <>
            <ParallaxSlide 
                name="P a t r o c i n a d o r e s" 
                image={IMGDEFAULT}
                linkTo=""/>
            <MDBContainer size="lg">
                        <section>
                            {/* <MDBCard className="px-3 py-2">
                                <MDBCardBody> */}
                                    {loading && <CircleToBlockLoading/>}
                                    <Partners data={info}></Partners>
                                {/* </MDBCardBody>
                            </MDBCard> */}
                        </section>
                </MDBContainer>
            </>
        )
    }
    return (
        <main style={{marginBottom: '0'}}>
            <ParallaxSlide 
                name="S e r v i c i o s" 
                image={IMGDEFAULT}
                linkTo="/servicios"/>
            <MDBContainer size="lg">
                <section className="text-center my-5">
                    <MDBRow>
                        {loading && <CircleToBlockLoading/>}
                        <Services services={dataServices}/>
                    </MDBRow>
                </section>
            </MDBContainer>
            <ParallaxSlide 
                name="R e c i e n t e s" 
                image={IMGDEFAULT}
                linkTo="/descubre/trabajos"/>
            <MDBContainer size="lg">
                <section>
                    {/* <MDBCard className="px-3 py-2">
                        <MDBCardBody> */}
                            {loading && <CircleToBlockLoading/>}
                            <Ourwork ourwork={dataOurwork}/>
                        {/* </MDBCardBody>
                    </MDBCard> */}
                </section>
            </MDBContainer>
            {PartnersExist(dataPartners)}
            <SEO
                title="Rincón de la Huasteca"
                description="Dedicados a compartir contenido multimedia: música, video y fotografía; ofreciendo servicios relacionados a estos." 
                url={window.location.href}
                image=""
                type="website"
             />
             <ParallaxSlide className="lastParallax"
                name="" 
                image={IMGDEFAULT}/>
        </main>
    )
}
export default Home
