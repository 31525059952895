import React from 'react'
import {
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBBtn,
    MDBView
  } from 'mdbreact'
import YouTube from 'react-youtube'

import { Link } from "react-router-dom";

const Ourwork = ({ourwork}) =>{

    const Media = (section) => {
        if(section.videos.length){
            const opts = {
                height: '250',
                width: '100%',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  autoplay: 0,
                },
              };
          
              return <YouTube videoId={section.videos[0]} className="rounded z-depth-2 mb-lg-0 mb-4"  opts={opts} />
        }else if (section.images.length){
            return (
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                    <img className="img-fluid" src={section.media} alt={section.title}/>
                </MDBView> 
            )
        }else if (section.audios.length){
            return (
                <div dangerouslySetInnerHTML={{__html:section.audios[0]}}></div>
            )
        }
    }
    const ParImpar = (section, index) => {
        // if(index % 2 === 0){    
            return <>
            <MDBCol md="5" middle >
                    {Media(section)}   
            </MDBCol>
            <MDBCol md="7" className='border border-5 rounded-3'>
                <h6 >
                    <h6 className="font-weight-bold mb-3">
                    <Link to={section.author[0]}> <span className="black-text"> {section.author[1]} </span></Link>
                    </h6>
                </h6>
                <h3 className="font-weight-bold mb-3 p-0">
                    <strong>{section.name}</strong>
                </h3>
                <p className="card-title">
                    {section.shortDescription}
                </p>
                <p className="gray-text"> 
                    <MDBIcon icon="clock"  className="pr-2" />  {section.date}
                </p>
                <MDBBtn color="green" size="md" className="waves-light ">
                   <Link to={section.link}> <span className="white-text"> Ver Mas</span></Link>
                </MDBBtn>
            </MDBCol>
            </>
        // }else{
        //     return <>
        //         <MDBCol md="7">
        //             <h6 >
        //                 <h6 className="font-weight-bold mb-3">
        //                 <Link to={section.author[0]}><span className="black-text"> {section.author[1]} </span></Link>
        //                 </h6>
        //             </h6>
        //             <h3 className="font-weight-bold mb-3 p-0">
        //                 <strong>{section.name}</strong>
        //             </h3>
        //             <p className="card-title">
        //                 {section.shortDescription}
        //             </p>
        //             <p className="gray-text"> 
        //                 <MDBIcon icon="clock"  className="pr-2" />  {section.date}
        //             </p>
        //             <MDBBtn color="green" size="md" className="waves-light">
        //                 <Link  to={section.link}> <span className="white-text"> Ver Mas</span></Link>
        //             </MDBBtn>
        //         </MDBCol>
        //         <MDBCol md="5" middle>
        //                 {Media(section)}
        //         </MDBCol>
        //     </>
        // }
    }
    return ourwork.map((section,index) => {
        return <div key={index}>
                    <MDBRow >
                        {ParImpar(section, index)}
                    </MDBRow>
                    <hr className="my-5" />
                </div>
    })
}

export default Ourwork