import React from 'react'
import {Link, useRouteMatch} from 'react-router-dom'
import {
    MDBCol,
    MDBIcon,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBRow,
    MDBView
  } from 'mdbreact'

const Items = ({list}) => {
    let {url } = useRouteMatch()

    const fixUrl = (link) =>{
        if (url === '/descubre/trabajos') {
            let rep = link.replace('descubre/', '')
            return rep
        }
        let cleanSame = link.substr(link.lastIndexOf('/'), link.length)
        let finalUrl =  url + cleanSame
        finalUrl = finalUrl.replace(/\/\//, '/')
        return finalUrl
    }

    const setImg = (info) => {
        if (info.images[0] !== undefined) {
            return info.images[0]
        }else{
            if (info.videos.length > 0) {
                return `http://img.youtube.com/vi/${info.videos[0]}/0.jpg`
            }else if(info.audios.length > 0){
                return 'https://firebasestorage.googleapis.com/v0/b/rincondelahuasteca-4a673.appspot.com/o/logoMusic.jpg?alt=media&token=74a2c6e6-271e-4268-a61a-5fc61f231456'
            }
        }
    }

    const loopAuthors = (data) => {
        return  data.map(element => {
             return <MDBCol md="4"  className="my-2">
                     <MDBCard narrow>
                        <MDBView cascade>
                            <Link to={fixUrl(element.link)}>
                                <div style={{
                                    backgroundImage: `url(${setImg(element)})`, 
                                    backgroundSize: 'cover',
                                    backgroundPosition:'center', 
                                    height:"200px"}}>

                                </div>
                            </Link>
                         </MDBView>
                         <MDBCardBody >
                             <MDBRow className="m-auto">
                                 <MDBCardTitle md="11" className="m-auto">{element.name}</MDBCardTitle>
                             </MDBRow>
                             <hr />
                             <MDBCardText >
                             {(element.shortDescription) ? element.shortDescription : element.description}
                             </MDBCardText>
                             <Link to={fixUrl(element.link)}>
                                 <MDBBtn color="dark-green" size="sm" >
                                     Conocer más
                                     <MDBIcon icon='angle-double-right' className='ml-2' />
                                 </MDBBtn>
                             </Link>
                         </MDBCardBody>
                     </MDBCard>
                 </MDBCol>
         });
         
     }

     return loopAuthors(list)
}

export default Items