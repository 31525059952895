import React, {useState, useEffect, useCallback} from 'react'
import MDBAvatar from './Avatar'
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images";
import YouTube from 'react-youtube'
import './styles/Profile.css'

import { 
    MDBCard,
    MDBCardBody, 
    MDBRow,
    MDBIcon,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBTabContent,
    MDBTabPane,
    MDBCardFooter,
    MDBCol,
    MDBBreadcrumb, 
    MDBBreadcrumbItem,
} from 'mdbreact';
import { Link, useRouteMatch } from 'react-router-dom';

const Profile = ({profileInfo}) => {
    const [activeItem, setActiveItem] = useState("1")
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    let {url } = useRouteMatch()
    useEffect(() => {
        if (profileInfo) {
            if (profileInfo.description !== '') {
                setActiveItem("1")
            }else if(profileInfo.images.length > 0){
                setActiveItem("2")
            }else if(profileInfo.videos.length > 0){
                setActiveItem("3")
            }else if(profileInfo.audios.length > 0){
                setActiveItem("4")
            }
        }
    }, [profileInfo])

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);
    
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const photos = infoImg => {
        let arrImg = []
        for (let index = 0; index < infoImg.images.length; index++) {
            const element = infoImg.images[index];
            let img = {}
            img['src'] = element
            img['width'] = 3
            img['height'] = 2
            arrImg.push(img)
        }
        return arrImg
    }

    const Description = desc => {
        if (desc === '') return null
        return <MDBNavItem>
                <MDBNavLink link to="#" active={activeItem === "1"} onClick={(() => setActiveItem("1"))} role="tab" >
                    Información
                </MDBNavLink>
            </MDBNavItem>
        
    }

    const Images = (img) => {
        if (img === undefined) return null 
        if (img.length === 0) return null
        return <MDBNavItem>
                    <MDBNavLink link to="#" active={activeItem === "2"} onClick={(() => setActiveItem("2"))} role="tab" >
                    Fotos
                    </MDBNavLink>
                </MDBNavItem>
        
    }

    const Videos = (vid) => {
        if (vid === undefined) return null 
        if (vid.length === 0) return null
        return <MDBNavItem>
                    <MDBNavLink link to="#" active={activeItem === "3"} onClick={(() => setActiveItem("3"))} role="tab" >
                    Videos
                    </MDBNavLink>
                </MDBNavItem>
    }

    const Audios = (aud) => {
        if (aud === undefined) return null 
        if (aud.length === 0) return null
        return <MDBNavItem>
                    <MDBNavLink link to="#" active={activeItem === "4"} onClick={(() => setActiveItem("4"))} role="tab" >
                    Audios
                    </MDBNavLink>
                </MDBNavItem>
        
    }

    const setAvatar = (info) => {
        if (info.images[0] !== undefined) {
            return info.images[0]
        }else{
            if (info.videos.length > 0) {
                return `http://img.youtube.com/vi/${info.videos[0]}/0.jpg`
            }else if(info.audios.length > 0){
                return 'https://firebasestorage.googleapis.com/v0/b/rincondelahuasteca-4a673.appspot.com/o/logoMusic.jpg?alt=media&token=74a2c6e6-271e-4268-a61a-5fc61f231456'
            }
        }
    }

    const videoList = (info) => {
        if (info.videos === undefined) return null
        const opts = {
            height: '350px',
            width: '100%',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
            },
          };
        return info.videos.map(element => {
            return <MDBCol md="12" middle >
                        <YouTube videoId={element} className=""  opts={opts} />
                    </MDBCol>
        });
    }
    const audioList = (info) => {
        if (info.audios === undefined) return null
        return info.audios.map(element => {
            return <MDBCol md="6" middle >
                         <div className="img-thumbnail iframeAudio"  dangerouslySetInnerHTML={{__html:element}}></div>
                    </MDBCol>
        });
    }

    const fixUrl = (u) => {
        let clean = u.substr(0, u.lastIndexOf('/'))
        return clean
    }

    if (!(profileInfo)) return <h1 style={{textAlign:'center'}}>
                                    Upps! ocurrió un error! <br/>
                                    Regresa a <Link to="/">Inicio</Link>
                                </h1>
    return <MDBRow>
                <MDBCard testimonial className="m-auto" style={{minWidth: '98%'}}>
                    <MDBBreadcrumb style={{position:'absolute'}}>
                        <MDBBreadcrumbItem active><Link to={fixUrl(url)}><MDBIcon icon='arrow-circle-left' size="2x" className="grey-text"/></Link></MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                    <MDBAvatar className='mx-auto white' style={{width:'10em', marginTop: '-5em'}}>
                            <div style={{
                                backgroundImage: `url(${setAvatar(profileInfo)})`, 
                                backgroundSize: 'cover',
                                backgroundPosition:'center', 
                                height:"150px"}}>
                            </div>
                    </MDBAvatar>
                    <MDBCardBody>
                        <h4 className='card-title'> {profileInfo.name}</h4>
                        <hr />
                        <p>
                        <MDBIcon icon='quote-left' />{profileInfo.shortDescription}
                        <MDBIcon icon='quote-right' />
                        </p>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <MDBNav className="nav-tabs mt-1">
                            {Description(profileInfo.description)}
                            {Images(profileInfo.images)}
                            {Videos(profileInfo.videos)}
                            {Audios(profileInfo.audios)}
                        </MDBNav>
                        <MDBTabContent activeItem={activeItem} >
                            
                            
                            <MDBTabPane className='contentHtml' tabId="1" role="tabpanel" dangerouslySetInnerHTML={{__html:profileInfo.description}}>
                                
                            </MDBTabPane>
                            <MDBTabPane tabId="2" role="tabpanel" className="p-2">
                                <Gallery 
                                photos={photos(profileInfo)} 
                                onClick={openLightbox}
                                
                                />
                                <ModalGateway>
                                    {viewerIsOpen ? (
                                    <Modal onClose={closeLightbox}>
                                        <Carousel
                                        currentIndex={currentImage}
                                        views={photos(profileInfo).map(x => ({
                                            ...x,
                                            srcset: x.srcSet,
                                            caption: x.title
                                        }))}
                                        />
                                    </Modal>
                                    ) : null}
                                </ModalGateway>
                            </MDBTabPane>
                            <MDBTabPane tabId="3" role="tabpanel">
                                <MDBRow>
                                    {videoList(profileInfo)}
                                </MDBRow>
                            </MDBTabPane>
                            <MDBTabPane tabId="4" role="tabpanel">
                                <MDBRow>
                                    {audioList(profileInfo)}
                                </MDBRow>
                            </MDBTabPane>
                        </MDBTabContent>
                    </MDBCardFooter>
                </MDBCard>
            </MDBRow>
}

export default Profile