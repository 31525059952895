import React, {useState} from 'react'
import Carousel from './CarouselMultiItem'
import MDBAvatar from './Avatar'
import { Link } from 'react-router-dom'
import {
    MDBIcon,
    MDBBtn,
    MDBView,
    MDBMask,
    MDBContainer,
    MDBRow,
    MDBCol
} from 'mdbreact'
import { IMGDEFAULT } from '../config'
import './styles/Intereses.css'

const Intereses = ({interes, exclude}) => {
    const [exist, setExist] = useState(0)
    let contador = []
    const fixUrl = (link) =>{
        let currentLink = window.location.pathname
        currentLink = currentLink.substring(0, currentLink.lastIndexOf('/'))
        let toLink = link.substring(link.lastIndexOf('/'), link.length)
        let finalUrl = currentLink + toLink
        finalUrl =  finalUrl.replace(/\/\//, '/')
        return finalUrl
    }

    const setAvatar = (info) => {
        if (info.images[0] !== undefined) {
            return info.images[0]
        }else{
            if (info.videos.length > 0) {
                return `http://img.youtube.com/vi/${info.videos[0]}/0.jpg`
            }else if(info.audios.length > 0){
                return 'https://firebasestorage.googleapis.com/v0/b/rincondelahuasteca-4a673.appspot.com/o/logoMusic.jpg?alt=media&token=74a2c6e6-271e-4268-a61a-5fc61f231456'
            }
        }
    }


    const items = (info) => {
       return  <Carousel >
                {
                info.map((item, index) =>{
                    if (item === undefined) {
                        return null
                    }
                    if (item.link === exclude.link) {
                        return null
                    }
                    return <div  key={index} style={{textAlign:'center', backgroundColor:'#fff'}} className=" rounded img-thumbnail" >
                            <MDBAvatar >
                                
                                    <Link to={fixUrl(item.link)} className="">
                                        <div className=" rounded img-thumbnail mx-auto  " style={{
                                            backgroundImage: `url(${setAvatar(item)})`, 
                                            backgroundSize: 'cover',
                                            backgroundPosition:'center', 
                                            height:"150px",
                                            width: "150px"}}
                                            >

                                        </div>
                                    </Link>
                            </MDBAvatar>
                            <h4 className=" mt-4">{item.name}</h4>
                            <p>
                            <MDBIcon icon="quote-left" className="pr-2" />
                            {item.shortDescription}
                            </p>
                            <Link to={fixUrl(item.link)}>
                                <MDBBtn color="dark-green" size="sm" >
                                    Más información
                                    <MDBIcon icon='angle-double-right' className='ml-2' />
                                </MDBBtn>
                            </Link>
                        </div>
                    })
                }
            </Carousel>
    }

    const existIntereses = (c) => {
        let count = 0
        c.map(el  => {
            if (el === undefined) {
                return null
            }
            if (el.link === exclude.link) {
                return null
            }
            count ++
        })
        if(count > 0){
            return <MDBView 
                    src={IMGDEFAULT}
                    fixed
                    >
                    <MDBMask className='rgba-black-medium' />
                    <MDBContainer
                        className=' justify-content-center align-items-center'
                        style={{ height: '100%', width: '100%',paddingTop: '3rem', paddingBottom: '3rem'}}
                    >
                        <MDBRow>
                            <MDBCol md='12' className='  text-center'>
                                <h3 className="text-center font-weight-bold white-text">Te puede interesar</h3>
                                {items(c)}
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBView>
        }
        return null
    }

    if (interes.length === 0 || exclude === undefined) return null
    
    return existIntereses(interes)
            
}

export default Intereses