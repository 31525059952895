import React, {useState, useEffect} from 'react'
import {
    useRouteMatch
} from 'react-router-dom'
import {
    MDBContainer
  } from 'mdbreact'
import { CircleToBlockLoading } from 'react-loadingg';
import {API_URL} from '../../../config'
import Profile from '../../Profile'
import Intereses from  '../../Intereses'
import SEO from '../../SEO'

const ChildWork = () => {
    const [work, setWork] = useState([])
    const [intereses, setIntereses] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingInt, setLoadingInt] = useState(true)
    let { url } = useRouteMatch()
    
    useEffect(() => {
        setLoading(true)
        setLoadingInt(true)
        const headers = {
        'Content-Type':'application/json'
        }
        fetch(API_URL + 'work', {
            headers: headers,
            method:'POST',
            body: JSON.stringify({'id':url.substr(1)})
        })
        .then(res => res.json())
        .then(data => {
            setWork(data.work[0])
            setLoading(false)
            fetch(API_URL + 'works', {
                headers: headers,
                method:'POST',
                body: JSON.stringify({'id':url.substr(1, url.lastIndexOf('/')-1)})
            })
            .then(res => res.json())
            .then(inter => {
                setIntereses(inter.res)
                setLoadingInt(false)
            })
        })
            
    }, [url])


    const loadProf = (info,status) => {
        if (status) {
            return <CircleToBlockLoading/>
        }
        return <Profile profileInfo={info}/>
    }
    const loadInt = (data, exclude, status) => {
        if (status) {
            return <CircleToBlockLoading/>
        }
        return <Intereses interes={data} exclude={exclude}/> 
    }
    return <main>
            <MDBContainer size="lg">
                {loadProf(work, loading)}
                <hr/>
                {loadInt(intereses, work, loadingInt)}
            </MDBContainer>
            <SEO
                title={work.name}
                description={work.shortDescription || work.description} 
                url={window.location.href}
                image={work.images}
                type="article"
             />
        </main>
    
}

export default ChildWork