import React, {useState, useEffect} from 'react'
import { useRouteMatch } from 'react-router-dom'
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCardFooter,
    MDBInput,
    MDBDropdown
  } from 'mdbreact'

import Items from '../../Items'
import { CircleToBlockLoading } from 'react-loadingg';
import {API_URL} from '../../../config'

const FilterWorks = () =>{
    const [order, setOrder] = useState('desc')
    const [works, setWorks] = useState([])
    const [loading, setLoading] = useState(true)
    let { url } = useRouteMatch() 

    useEffect(() => {
        setLoading(true)
        const headers = {
            'Content-Type':'application/json'
            }
            fetch(API_URL + 'allworks', {
                headers: headers,
                method:'POST',
                body: JSON.stringify({'order':order})
            })
            .then(res => res.json())
            .then(data => {
                setWorks(data.res)
                setLoading(false)
            })
    }, [order])
    const existItems = (info) => {
        if (info.length === 0) return null 
        return <>
                <hr/>
                <MDBCard>
                    <MDBCardBody>
                        <MDBRow>
                            <Items list={info}/>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
                </>
    } 
    const loadItems = (i, s) => {
        if (s) {
            return <CircleToBlockLoading/>
        }
        return existItems(i)
    }
    const handleChange = (event) => {
        setOrder(event.target.value)
    }
    return <main>
            <MDBContainer size="lg">
                <MDBRow >
                    <MDBCard style={{ margin:'-48px auto auto', minWidth:'98%'}}>
                        <MDBCardBody>
                            <div className="float-right">
                                <div className="grey-text d-flex" style={{alignItems:'center'}}>
                                    <label className="align-middle m-0">Ordenar</label>
                                    <select className="form-control ml-1" value={order} onChange={handleChange}>
                                        <option value="desc">Más Reciente</option>
                                        <option value="asc">Más Antiguo</option>
                                    </select>
                                </div>
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter>

                        </MDBCardFooter>
                    </MDBCard>
                </MDBRow>
                {loadItems(works, loading)}
            </MDBContainer>
            </main>
}

export default FilterWorks